import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-awesome-styled-grid"
import styled from "styled-components"
import { ArrowButton, SimpleHoverButton } from "../../components/Button"
import { Heading2 } from "../../components/Heading"
import Layout from "../../components/Layout"
import { firestore } from "../../utils/firebase"
import { collection, addDoc } from "firebase/firestore"
import { getCookie, setCookie } from "../../utils/cookie-helper"
import Block from "../../components/Block"
import { Hero, HeroContent, HeroHeading } from "../../components/Hero"
import BackgroundImage from "gatsby-background-image"

const Background = ({ image, className, children }) => {
  return (
    <BackgroundImage
      className={className}
      fluid={image.childImageSharp.fluid}
      style={{ height: "100%" }}
    >
      {children}
    </BackgroundImage>
  )
}

const TextCenter = styled.p`
  text-align: center;
  text-decoration: underline;
`

const NavigationButtons = styled(Row)`
  margin-top: 20px;
`

const QuestionText = styled(Heading2)`
  text-align: center;

  ${props => props.small && "font-size: 1.2rem;"}
`

const ProductTitle = styled.p`
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0;
`

const ProductPrice = styled.p`
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: 0;
`

const CheckboxWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid #3795ed;
  border-radius: 4px;
  margin-bottom: 10px;
`

const StyledCheckbox = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;

  &:checked ~ div {
    color: #fff;
    background-color: #3795ed;
  }
`

const CheckboxAnswer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  box-sizing: border-box;
  transition: background-color 0.3s ease, color 0.3s ease;
`

const Range = styled.input`
  outline: 0;
  border: 0;
  border-radius: 500px;
  width: 100%;
  max-width: 400px;
  max-width: 100%;
  margin: 24px 0 16px;
  transition: box-shadow 0.2s ease-in-out;

  & {
    overflow: hidden;
    height: 40px;
    -webkit-appearance: none;
    background-color: #ddd;
  }

  &::-webkit-slider-runnable-track {
    height: 40px;
    -webkit-appearance: none;
    color: #444;
    transition: box-shadow 0.2s ease-in-out;
  }

  &::-webkit-slider-thumb {
    width: 40px;
    -webkit-appearance: none;
    height: 40px;
    cursor: ew-resize;
    background: #fff;
    box-shadow: -340px 0 0 320px #1597ff, inset 0 0 0 40px #1597ff;
    border-radius: 50%;
    transition: box-shadow 0.2s ease-in-out;
    position: relative;
  }

  &:active::-webkit-slider-thumb {
    background: #fff;
    box-shadow: -340px 0 0 320px #1597ff, inset 0 0 0 3px #1597ff;
  }

  &::-moz-range-progress {
    background-color: #43e5f7;
  }
  &::-moz-range-track {
    background-color: #9a905d;
  }

  &::-ms-fill-lower {
    background-color: #43e5f7;
  }
  &::-ms-fill-upper {
    background-color: #9a905d;
  }
`

const RangeWrapper = styled.div`
  width: 100%;
  max-width: 400px;
`

const RangeLabelWrapper = styled.div`
  width: 100%;
  max-width: 350px;
  min-height: 40px;
  position: relative;
`

const RangeDescriptionRow = styled(Row)`
  width: 100%;
  max-width: 600px;
  margin: -10px auto 0 auto;
`

const RangeDescription = styled.span`
  font-size: 0.85rem;
  line-height: 1;
`

const RangeLabel = styled.div`
  position: absolute;
  left: ${props => props.progress}%;
  border-radius: 500px;
  font-size: 1.4rem;
  color: #fff;
  background-color: #3795ed;
  font-weight: 700;
  text-align: center;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%);
`

const Answer = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
`

const QaBlock = styled(Block)`
  min-height: 30vh;
`

const Input = ({ type, questionKey, answer, checked, handleChange }) => {
  return (
    <CheckboxWrapper>
      <StyledCheckbox
        type={type}
        name={questionKey}
        value={answer.key}
        checked={checked}
        onChange={handleChange}
      />
      <CheckboxAnswer>
        <Answer>{answer.answer}</Answer>
      </CheckboxAnswer>
    </CheckboxWrapper>
  )
}

const SingleQuestion = ({ question, answer, setAnswer }) => {
  const handleChange = e => {
    setAnswer(e.target.value)
  }

  return (
    <Row justify="center">
      {question.answers.map(a => (
        <Col xs={12} md={6} lg={question.answers.length === 3 ? 4 : 6}>
          <Input
            type="radio"
            questionKey={question.key}
            answer={a}
            checked={answer === a.key}
            handleChange={handleChange}
          />
        </Col>
      ))}
    </Row>
  )
}

const MultiQuestion = ({ question, answer, setAnswer }) => {
  const handleChange = e => {
    let values = answer || []

    if (e.target.checked) {
      values.push(e.target.value)
    } else {
      values = values.filter(v => v !== e.target.value)
    }

    setAnswer(values.filter(v => v && v.length > 0))
  }

  return (
    <Row justify="center">
      {question.answers.map(a => (
        <Col xs={12} md={6}>
          <Input
            type="checkbox"
            questionKey={question.key}
            answer={a}
            checked={answer?.includes(a.key)}
            handleChange={handleChange}
          />
        </Col>
      ))}
    </Row>
  )
}

const RangeQuestion = ({ question, answer, setAnswer }) => {
  const handleChange = e => {
    setAnswer(parseInt(e.target.value))
  }

  const minValue = question.min || 1
  const maxValue = question.max || 10
  const valueRange = maxValue - minValue

  const defaultValue = Math.floor(minValue + (maxValue - minValue) / 2)

  const value = parseInt(answer) || defaultValue

  const progress = (100 * (value - minValue)) / valueRange

  useEffect(() => {
    setAnswer(value)
  }, [question.key])

  return (
    <>
      <Row>
        <Col align="center">
          <RangeWrapper>
            <Range
              type="range"
              min={minValue}
              max={maxValue}
              step="1"
              name={question.key}
              value={value}
              onChange={handleChange}
            />
          </RangeWrapper>
        </Col>
      </Row>
      <RangeDescriptionRow>
        <Col xs={6}>
          <RangeDescription>
            {question.labelMin || "Stimme gar nicht zu"}
          </RangeDescription>
        </Col>
        <Col xs={6} align="flex-end">
          <RangeDescription>
            {question.labelMax || "Stimme komplett zu"}
          </RangeDescription>
        </Col>
      </RangeDescriptionRow>
      <Row>
        <Col align="center">
          <RangeLabelWrapper>
            <RangeLabel progress={progress}>
              <span>{value}</span>
            </RangeLabel>
          </RangeLabelWrapper>
        </Col>
      </Row>
    </>
  )
}

const Question = ({ question, answer, setAnswer }) => {
  if (question.type === "single") {
    return (
      <SingleQuestion
        question={question}
        answer={answer}
        setAnswer={setAnswer}
      />
    )
  }

  if (question.type === "multi") {
    return (
      <MultiQuestion
        question={question}
        answer={answer}
        setAnswer={setAnswer}
      />
    )
  }

  if (question.type === "range") {
    return (
      <RangeQuestion
        question={question}
        answer={answer}
        setAnswer={setAnswer}
      />
    )
  }
}

const uuid = () => {
  var lut = []
  for (var i = 0; i < 256; i++) {
    lut[i] = (i < 16 ? "0" : "") + i.toString(16)
  }

  function e7() {
    var d0 = (Math.random() * 0xffffffff) | 0
    var d1 = (Math.random() * 0xffffffff) | 0
    var d2 = (Math.random() * 0xffffffff) | 0
    var d3 = (Math.random() * 0xffffffff) | 0
    return (
      lut[d0 & 0xff] +
      lut[(d0 >> 8) & 0xff] +
      lut[(d0 >> 16) & 0xff] +
      lut[(d0 >> 24) & 0xff] +
      "-" +
      lut[d1 & 0xff] +
      lut[(d1 >> 8) & 0xff] +
      "-" +
      lut[((d1 >> 16) & 0x0f) | 0x40] +
      lut[(d1 >> 24) & 0xff] +
      "-" +
      lut[(d2 & 0x3f) | 0x80] +
      lut[(d2 >> 8) & 0xff] +
      "-" +
      lut[(d2 >> 16) & 0xff] +
      lut[(d2 >> 24) & 0xff] +
      lut[d3 & 0xff] +
      lut[(d3 >> 8) & 0xff] +
      lut[(d3 >> 16) & 0xff] +
      lut[(d3 >> 24) & 0xff]
    )
  }
  return e7()
}

const writeToDb = async data => {
  try {
    await addDoc(collection(firestore, "fragebogen"), data)
  } catch (e) {}
}

const Fragebogen = ({ location, data: { fragebogen, image } }) => {
  const [questionId, setQuestionId] = useState(0)
  const [answers, setAnswers] = useState({})
  const [dataWritten, setDataWritten] = useState(false)

  let userId = getCookie("qaUserId")

  if (userId === undefined) {
    userId = uuid()
    setCookie("qaUserId", userId, 30)
  }

  const question =
    questionId > fragebogen.questions.length - 1
      ? undefined
      : fragebogen.questions[questionId]

  const results =
    questionId > fragebogen.questions.length - 1
      ? fragebogen.results.find(r =>
          r.answers.every(
            ({ question, answer }) => answers[question] === answer
          )
        )?.results
      : undefined

  useEffect(() => {
    if (!dataWritten && results !== undefined) {
      writeToDb({ ...answers, userId, timestamp: Date.now() })
      setDataWritten(true)
    }
  }, results)

  return (
    <Layout
      location={location}
      title="CBD Öl Test – das beste CBD Öl finden"
      mt0
      light
    >
      <Hero heightVh={50}>
        <Background image={image}>
          <HeroContent align="flex-end">
            <Container>
              <Row>
                <Col>
                  <HeroHeading>
                    CBD Öl Test – das beste CBD Öl finden
                  </HeroHeading>
                </Col>
              </Row>
            </Container>
          </HeroContent>
        </Background>
      </Hero>
      <Container>
        <QaBlock>
          {questionId < fragebogen.questions.length ? (
            <>
              <Row>
                <Col>
                  {question.hint && (
                    <QuestionText small>{question.hint}</QuestionText>
                  )}
                  <QuestionText>{question.question}</QuestionText>
                  <Question
                    question={question}
                    answer={answers[question.key]}
                    setAnswer={answer => {
                      setAnswers({ ...answers, [question.key]: answer })
                    }}
                  />
                </Col>
              </Row>
              <NavigationButtons>
                <Col>
                  {questionId > 0 && (
                    <div>
                      <SimpleHoverButton
                        onClick={() => {
                          setQuestionId(q => q - 1)
                        }}
                      >
                        Zurück
                      </SimpleHoverButton>
                    </div>
                  )}
                </Col>
                <Col align="flex-end">
                  {(!question.required || answers[question.key]) && (
                    <div>
                      <SimpleHoverButton
                        onClick={() => {
                          setQuestionId(q => q + 1)
                        }}
                      >
                        {questionId === fragebogen.questions.length - 1
                          ? "Ergebnis anzeigen"
                          : "Weiter"}
                      </SimpleHoverButton>
                    </div>
                  )}
                </Col>
              </NavigationButtons>
            </>
          ) : (
            <>
              <Row>
                <Col>
                  <QuestionText small>
                    Ihre persönlichen Empfehlungen
                  </QuestionText>
                </Col>
              </Row>
              <Row>
                {results.map((key, idx) => {
                  const product = fragebogen.products.find(p => p.key === key)
                  return (
                    <>
                      <Col xs={12} md={5} align="center" key={product.key}>
                        <GatsbyImage
                          image={getImage(product.image)}
                          alt={product.name}
                        />
                        <ProductTitle>{product.name}</ProductTitle>
                        <ProductPrice>
                          {product.price.toFixed(2).replace(".", ",")} €
                        </ProductPrice>
                        <p>(reicht für ca. 3 Monate)</p>
                        <div>
                          <ArrowButton
                            href={product.url}
                            category="fragebogen"
                            action={product.key}
                            label="result"
                            width={18}
                          >
                            Weiter informieren
                          </ArrowButton>
                        </div>
                      </Col>
                      {idx === 0 && (
                        <Col justify="center">
                          <TextCenter>oder</TextCenter>
                        </Col>
                      )}
                    </>
                  )
                })}
              </Row>
            </>
          )}
        </QaBlock>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    fragebogen: dataJson(key: { eq: "fragebogen" }) {
      products {
        image {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              width: 250
            )
          }
        }
        key
        name
        price
        url
      }
      questions {
        answers {
          answer
          key
        }
        hint
        key
        labelMax
        labelMin
        max
        min
        question
        required
        type
      }
      results {
        results
        answers {
          answer
          question
        }
      }
    }
    image: file(relativePath: { eq: "stock/oil-water.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Fragebogen
